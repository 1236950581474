import * as React from "react";
import { useEffect, useState } from "react";
import { Link } from "gatsby";
import scoring_icon from "../images/scoring-icon.png";
export const links = [
  {
    text: "Home",
    to: "/",
  },
  {
    text: "Objective",
    to: "/objective",
  },
  {
    text: "Consortium",
    to: "/consortium",
  },
  {
    text: "Description of work",
    to: "/workDescription",
  },
  {
    text: "SCORING Demonstrations",
    childs: [
      {
        text: "Intent-Based Networking",
        to: "/demos/ibn",
      },
      {
        text: "In-Network Computing",
        to: "/demos/inc",
      },
    ],
  },
  {
    text: "Publications",
    to: "/publications",
  },
  {
    text: "Contact",
    to: "/contact",
  },
];


const NavBar = ({ siteTitle, pagePath }) => {
  const [toggle, setToggle] = useState(false);
  const [dropdowns, setDropDowns] = useState([]);
  console.log(pagePath);

  const onToggleNavBar = () => {
    setToggle(!toggle);
  };

  // use effect to process the navbar components, should be done only once
  useEffect(() => {
    // get the dropdown elements
    links.forEach((link) => {
      if (link.childs) {
        setDropDowns((dropdowns) => [...dropdowns, false]);
      }
    });
  }, []);

  return (
    <nav class="bg-white border-gray-200 dark:bg-gray-900 mt-4">
      <div class="max-w-screen-xl flex flex-wrap items-start justify-between mx-auto p-4 md:h-48">
        <Link
          to="/"
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <img className="h-12 mr-3 mb-0" src={scoring_icon}></img>
          <span class="self-center text-3xl font-semibold  whitespace-nowrap text-scoringLightBlue dark:text-white">
            SCORING
          </span>
        </Link>
        <button
          type="button"
          onClick={onToggleNavBar}
          class="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        >
          <span class="sr-only">Open main menu</span>
          <svg
            class="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clip-rule="evenodd"
            >
            </path>
          </svg>
        </button>
        <div
          class={` ${toggle ? "block" : "hidden"} w-full md:block md:w-auto md:mt-3 `}
        >
          <ul class="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
            {links.map((link, index) => {
              if (link.childs) {
                return (
                  <li>
                    <button
                      onClick={() => {
                        setDropDowns((dropdowns) => {
                          dropdowns[index] = !dropdowns[index];
                          return [...dropdowns];
                        });
                      }}
                      class="flex items-center text-lg justify-between w-full py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 md:w-auto dark:text-white md:dark:hover:text-blue-500 dark:focus:text-white dark:border-gray-700 dark:hover:bg-gray-700 md:dark:hover:bg-transparent"
                    >
                      <span>{link.text}</span>
                      <svg
                        class="w-5 h-5 ml-1"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        >
                        </path>
                      </svg>
                    </button>
                    <div
                      id="dropdownNavbar"
                      class={`z-10 ${dropdowns[index] ? "" : "hidden"} font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-auto  dark:bg-gray-700 dark:divide-gray-600`}
                    >
                      <ul
                        class="py-2 text-sm text-gray-700 dark:text-gray-400"
                      >
                        {link.childs.map((child) => (
                        <li key={child}>
                          <a
                            href={child.to}
                            class={`block rounded px-4 py-2 mr-3 ${pagePath == child.to ? "text-white bg-scoringBlue md:bg-transparent md:text-scoringBlue" : "text-gray-900 bg-transparent md:bg-transparent md:text-gray-900"} hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white`}
                          >
                              <span>{child.text}</span>
                          </a>
                        </li>
                      ))}
                      </ul>
                    </div>
                  </li>
                );
              } else {
                return (
                  <li key={link}>
                    <a
                      href={link.to}
                      class={`block text-lg py-2 pl-3 pr-4 ${pagePath == link.to ? "text-white bg-scoringBlue md:bg-transparent md:text-scoringBlue" : "text-gray-900 bg-transparent md:bg-transparent md:text-gray-900 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 md:w-auto"}  rounded  md:p-0 md:dark:text-blue-500 dark:bg-blue-600 md:dark:bg-transparent`}
                    >
                      {link.text}
                    </a>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
