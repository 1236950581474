import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import publications from "../data/publications.json"
import "./publications.css"
const WorkDescriptionPage = ({ location }) => {
  return (
    <Layout pagePath={location.pathname}>
      <div className="flex flex-col mt-14">
        <h1 className=" text-scoringBlue">Publications</h1>
        <div className="mt-5">
          {Object.entries(publications).map(category => (
            <div>
              <h3>{category[0]}</h3>
              <ol className=" list-decimal pl-8" id="publications">
                {category[1].map(({ publication, link }) => (
                  <li id="pub">
                    <p>
                      {publication} {" "}
                      <a href={link}>
                      <strong className="text-scoringBlue">{link}</strong>
                      </a>
                    </p>
                  </li>
                ))}
              </ol>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default WorkDescriptionPage
