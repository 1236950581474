/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import NavBar from "./navbar"
import "./layout.css"
import l3i from "../images/univs/l3i.png"
const Layout = ({ pagePath, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="font-sans" style={{ backgroundColor: "#FFFEFF" }}>
      <NavBar
        siteTitle={data.site.siteMetadata?.title || `Title`}
        pagePath={pagePath}
      />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1024,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <main style={{ minHeight: "50vh" }}>{children}</main>
        <footer>
          <div className="flex items-center justify-between mt-10">
            <span className="text-2xl">© {new Date().getFullYear()}</span>
            <img className="w-20" src={l3i} alt="l3i"></img>
          </div>
        </footer>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
